/* eslint-disable max-nested-callbacks */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../services/axios';
import actionTypes from './actionTypes';
import { IBasketItem, IQuantityOperation } from './models';
import { RootState } from '../../store/store';
import { BasketItemType } from './models';
import { IProductComponents } from '../common/models/productModels';
import moment from 'moment';

export const addToBasket = createAsyncThunk(
  actionTypes.ADD_TO_BASKET,
  async (
    basketItems: IBasketItem | IBasketItem[],
    thunkApi
  ): Promise<[items: IBasketItem[], lastId: string]> => {
    const itemDate =
      !Array.isArray(basketItems) && basketItems.date
        ? moment(basketItems.date)
        : moment((basketItems as IBasketItem).startDate);
    if (itemDate.isBefore(moment().startOf('day'))) {
      throw new Error('Nie można dodać produktu z datą w przeszłości');
    }

    const rootState = thunkApi.getState() as RootState;
    const state = rootState.basket;
    let items = structuredClone(state.items);

    if (!items) {
      items = [];
    }

    if (!Array.isArray(basketItems)) {
      basketItems = [basketItems];
    }

    for (const basketItem of basketItems) {
      const {
        item,
        itemType,
        date,
        startDate,
        endDate,
        adultCount,
        childrenAges,
        consumerCategoryId,
        licencePlates,
        name,
        selectedHour,
      } = basketItem;

      const id = `${item.id}_${itemType}_${
        date || startDate
      }_${licencePlates?.join(
        '_'
      )}_${consumerCategoryId}_${adultCount}_${childrenAges?.join('_')}`;
      const existingBasketItem = items.find((x) => {
        if (x.itemType === BasketItemType.SkiPass) {
          return x.id === id;
        } else {
          return (
            x.id === id &&
            (x.date === date ||
              (x.startDate === startDate && x.endDate === endDate)) &&
            x.adultCount === adultCount &&
            x.childrenAges?.length === childrenAges?.length &&
            x.name === name &&
            x.productComponents?.map((product: IProductComponents) =>
              product?.productAdditionalSettings.map((item) => item?.value)
            ) === selectedHour
          );
        }
      });

      if (existingBasketItem) {
        existingBasketItem.quantity += basketItem.quantity;
      } else {
        basketItem.id = id;
        items.push(basketItem);
      }
    }

    try {
      const data = JSON.stringify(items);
      const response = await axiosInstance.put<IBasketItem[]>(
        '/v1/order/basket/recalculate',
        data
      );

      return [response.data, items[items.length - 1].id];
    } catch (e) {
      console.error('Error while recalculating', JSON.stringify(e));
      return [items, '']; // Tu możesz zdecydować, co zwrócić w przypadku błędu
    }
  }
);

export const removeBasketItem = createAsyncThunk(
  actionTypes.REMOVE_FROM_BASKET,
  async (
    basketItemId: string,
    thunkApi
  ): Promise<[items: IBasketItem[], removed: boolean]> => {
    const rootState = thunkApi.getState() as RootState;
    const state = rootState.basket;
    const items = structuredClone(state.items);

    const itemIndex = items.findIndex((x) => x.id === basketItemId);
    if (itemIndex < 0) {
      console.warn('Item doesn`t exist in basket.');
      return [items, false];
    }
    items.splice(itemIndex, 1);

    try {
      const data = JSON.stringify(items);
      const response = await axiosInstance.put<IBasketItem[]>(
        '/v1/order/basket/recalculate',
        data
      );

      return [response.data, true];
    } catch (e) {
      console.error('Error while recalculating', JSON.stringify(e));
      //toast.error(i18n.t('common:error_message'));
      return [items, true];
    }
  }
);

export const addQuantityToBasketItem = createAsyncThunk(
  actionTypes.ADD_QUANTITY_BASKET_ITEM,
  async (
    quantityOperation: IQuantityOperation,
    thunkApi
  ): Promise<[items: IBasketItem[], changed: boolean]> => {
    const rootState = thunkApi.getState() as RootState;
    const state = rootState.basket;
    const items = structuredClone(state.items);

    const item = items.find((x) => x.id === quantityOperation.basketId);

    if (!item) {
      console.warn('Item doesn`t exist in basket.');
      return [items, false];
    }

    item.quantity += quantityOperation.changeValueBy;
    if (item.quantity <= 0) {
      items.splice(
        items.findIndex((x) => x.id === quantityOperation.basketId),
        1
      );
    }

    try {
      const data = JSON.stringify(items);
      const response = await axiosInstance.put<IBasketItem[]>(
        '/v1/order/basket/recalculate',
        data
      );

      return [response.data, true];
    } catch (e) {
      console.error('Error while recalculating', JSON.stringify(e));
      //toast.error(i18n.t('common:error_message'));
      return [items, true];
    }
  }
);
